import { createRouter, createWebHashHistory } from 'vue-router'
// import { createRouter } from 'vue-router'


const routes = [{
  path: '/',
  redirect: '/index'
},
{
  path: '/index',
  name: 'Index',
  title: '匿名提问箱',
  component: () => import('@/view/PMailBox.vue')
},
{
  path: '/newQuestion',
  name: 'NewQuestion',
  component: () => import('@/view/EditQuestionView.vue')
},
{
    path: '/newAnswer',
    name: 'newAnswer',
    component: () => import('@/view/EditAnswerView.vue')
},
{
    path: '/auth',
    name: 'Auth',
    component: () => import('@/view/AuthView.vue')
},
{
  path: '/questionList',
  name: 'QuestionList',
  component: () => import('@/view/QuestionListView.vue')
  // component: () => import('@/components/MSwipeCell.vue')
}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

import { useUserStore } from '@/pinia/modules/user';

const scretPage = new Set(["QuestionList"])
// const scretPage = new Set([])
router.beforeEach(async (to) => {
  const userStore = useUserStore()
  if ( userStore.token === '' && scretPage.has(to.name) 
  ) {
    // 将用户重定向到登录页面
    return { name: 'Auth' }
  }
})

export default router