import { Badge,Button,Cell,Col,Icon,Popup,Row,Sticky,Tag,Toast,Image, CellGroup, SwipeCell, PasswordInput, NumberKeyboard, Field, Tabs, Tab, List, PullRefresh } from "vant";

export function useVant(app){
    app.use(Button)
    app.use(Toast)
    app.use(Cell)
    app.use(Icon)
    app.use(Popup)
    app.use(Image)
    app.use(Badge)
    app.use(Row)
    app.use(Col)
    app.use(Sticky)
    app.use(Tag)
    app.use(CellGroup)
    app.use(SwipeCell)
    app.use(PasswordInput)
    app.use(NumberKeyboard)
    app.use(Field)
    app.use(Tabs)
    app.use(Tab)
    app.use(List)
    app.use(PullRefresh)
}