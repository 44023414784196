import { defineStore } from "pinia"

export const useUserStore = defineStore('user', {
    state: ()=>{
        return {
            boxId : 100001,
            boxInfo: {
                name: "",
            },
            token: window.localStorage.getItem('token') || '',
        }
    },
    getters:{},
    actions:{},
})