<template>
  <!-- <TouchAskRoom msg="Welcome to Your Vue.js App"/> -->
  <div id="app">
      <router-view />
    <div
      style="position:fixed;text-align:center;bottom:2px;margin:0 auto;width:100%;color: #5c6b77"
    >
    
      <a
        target="_blank"
        style="color: #5c6b77;  font-size: x-small;"
        href="https://beian.miit.gov.cn/"
        >备案号: 湘ICP备2022017487号 | 余温晨 @2021-2022 </a
      >
      <!-- &nbsp;  -->
    </div>

  </div>
</template>

<script>
// import TouchAskRoom from './view/TouchAskRoom.vue'

export default {
  name: 'App',
  components: {
    // TouchAskRoom
  }
}
</script>

<style>
body {
  background: rgb(240, 240, 240);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
  /* margin-top: 60px; */
}
</style>
