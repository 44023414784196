import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from '@/pinia/index'
import { useVant } from './config/vant.config'
// import { Toast,Vant } from 'vant'
import 'vant/lib/index.css'




const app = createApp(App)
    .use(store)
    .use(router)
useVant(app)    
app.mount('#app')
